<template>
  <a-card :bordered="false">
    <div class="table-page-search-wrapper">
      <a-form layout="inline">
        <a-row :gutter="GLOBAL.queryRowGutter">
          <a-col v-bind="GLOBAL.queryColSpan">
            <a-form-item label="交易日期">
              <a-range-picker @change="onDateChange" v-model="defaultDate" />
            </a-form-item>
          </a-col>
          <a-col v-bind="GLOBAL.queryColSpan">
            <a-form-item label="认领日期">
              <a-range-picker @change="onClaimDateChange" v-model="claimDate" />
            </a-form-item>
          </a-col>
          <a-col v-bind="GLOBAL.queryColSpan">
            <a-form-item label="收款人开户行">
              <a-select
                v-model="queryParam['bank_id']"
                showSearch
                allowClear
                placeholder="请选择收款人开户行"
              >
                <a-select-option :value="item.id" v-for="item in bankOps" :key="item.id">
                  {{ item.bank }}/{{ item.bank_account }}
                </a-select-option>
              </a-select>
            </a-form-item>
          </a-col>
          <a-col v-bind="GLOBAL.queryColSpan">
            <a-form-item label="币种">
              <a-input v-model.trim="queryParam['currency']" placeholder="根据币种搜索" />
            </a-form-item>
          </a-col>
          <a-col v-bind="GLOBAL.queryColSpan">
            <a-form-model-item label="状态" prop="flag">
              <a-select
                allowClear
                style="width: 100%"
                v-model="queryParam['flag']"
                @change="moneyFlagChange"
                placeholder="请选择状态"
              >
                <a-select-option v-for="op in moneyFlagOps" :key="op.value">{{ op.name }} </a-select-option>
              </a-select>
            </a-form-model-item>
          </a-col>
          <a-col v-bind="GLOBAL.queryColSpan">
            <a-form-item label="付款人名称">
              <a-input v-model.trim="queryParam['pay_name']" placeholder="根据付款人名称搜索" />
            </a-form-item>
          </a-col>
          <a-col v-bind="GLOBAL.queryColSpan">
            <a-form-item label="银行流水号">
              <a-input v-model.trim="queryParam['bank_serial_number']" placeholder="根据银行流水号搜索" />
            </a-form-item>
          </a-col>
          <a-col v-bind="GLOBAL.queryColSpan">
            <a-form-item label="发票号">
              <a-input v-model.trim="queryParam['invoice_no']" placeholder="根据发票号搜索" />
            </a-form-item>
          </a-col>
          <a-col v-bind="GLOBAL.queryColSpan">
            <a-form-item label="凭证状态">
              <a-select
                allowClear
                style="width: 100%"
                v-model="queryParam['cert_status']"
                placeholder="请选择凭证状态"
              >
                <a-select-option v-for="op in certStatusOps" :key="op.value">{{ op.name }} </a-select-option>
              </a-select>
            </a-form-item>
          </a-col>
          <a-col v-bind="GLOBAL.queryColSpan">
            <a-form-item label="凭证号">
              <a-input v-model.trim="queryParam['cert_num']" placeholder="根据凭证号搜索" />
            </a-form-item>
          </a-col>
          <a-col :span="3">
            <a-form-item label="账款类型">
              <a-select
                allowClear
                style="width: 100%"
                v-model="queryParam['is_advance']"
                @change="isAdvanceFlagChange"
                placeholder="请选择账款类型"
              >
                <a-select-option v-for="op in isAdvanceOps" :key="op.value">{{ op.name }} </a-select-option>
              </a-select>
            </a-form-item>
          </a-col>
          <a-col :span="3">
            <a-form-item label="部门">
              <a-select
                allowClear
                placeholder="请选择部门"
                style="width: 100%"
                v-model="queryParam.department"
              >
                <a-select-option v-for="op in this.GLOBAL.finDepartment" :key="op.value">{{
                  op.name
                }}</a-select-option>
              </a-select>
            </a-form-item>
          </a-col>
          <a-col :span="3">
            <a-form-item label="凭证创建人">
              <a-select
                allowClear
                style="width: 100%"
                v-model="queryParam.cert_user_id"
              >
                <a-select-option v-for="op in fmsUserOps" :key="op.id">{{
                  op.name
                }}</a-select-option>
              </a-select>
            </a-form-item>
          </a-col>
        </a-row>
      </a-form>
    </div>

    <div class="table-operator">
      <a-button type="query" icon="search" @click="$refs.table.refresh(true)">查询</a-button>
      <a-button type="primary" @click="handleAccountType" :disabled="selectedRowKeys.length <= 0">设置账款类型</a-button>
      <a-button type="danger" :disabled="!selectedRowKeys.length" @click="batchGenerateCert">批量生成凭证</a-button>
      <a-button type="primary" :disabled="!selectedRowKeys.length" @click="batchHandleConfirm">批量确认凭证</a-button>
      <a-button type="primary" :disabled="!selectedRowKeys.length" @click="downloadCert('all')">下载凭证</a-button>
      <a-popover
        v-model="popVisible"
        title="选择月份"
        trigger="click"
      >
        <a slot="content">
          <p><a-range-picker @change="onExportYmsPosChargeDateChange" /></p>
          <p><a-button type="primary" @click="handleExportPosPayDetailSheet">确定</a-button></p>
        </a>
        <a-button type="primary" v-if="queryParam.department === 6">导出堆场POS机计税清单</a-button>
      </a-popover>
    </div>

    <s-table
      ref="table"
      size="small"
      rowKey="id"
      :columns="columns"
      :data="loadData"
      :scroll="{ x: 'max-content' }"
      :rowSelection="{ selectedRowKeys: this.selectedRowKeys, onChange: this.onSelectChange }"
    >
      <span slot="serial" slot-scope="text, record, index">
        {{ index + 1 }}
      </span>
      <div slot="certInfo" slot-scope="text">
        <span style="display:block" v-for="item in text" :key="item.id">{{ item.cert_num + '(' + transCertStatus(item.cert_status) + ')' }}</span>
      </div>
      <div slot="invoiceNoList" slot-scope="text">
        <span style="display:block" v-for="item in text" :key="item">{{ item }}</span>
      </div>
      <span slot="action" slot-scope="text, record">
        <template>
          <a @click="updatePayer(record)">指定付款人</a>
          <a-divider type="vertical" />
          <a v-if="record.is_advance !== 0" style="margin-right:15px" @click="handleClaim(record)">资金认领信息</a>
          <a v-if="record.is_advance !== 0" @click="previewCert(record)">预览凭证</a>
        </template>
      </span>
    </s-table>

    <a-modal
      :visible="accountTypeVisible"
      :confirmLoading="confirmLoading"
      title="设置账款类型"
      @cancel="handleCloseAccountType"
      @ok="handleSubmitAccountType"
    >
      <p>请选择账款类型：</p>
      <a-radio-group v-model="accountType" button-style="solid">
        <a-radio-button :value="1">
          非预收
        </a-radio-button>
        <a-radio-button :value="2">
          预收
        </a-radio-button>
        <div style="margin-top:10px">
          <a-select
            allowClear
            placeholder="请选择部门"
            style="width: 100%"
            v-model="accountDepartment"
          >
            <a-select-option v-for="op in this.GLOBAL.departmentMaps" :key="op.value">{{ op.name }}</a-select-option>
          </a-select>
          <a-range-picker @change="onDealDateChange" style="margin-top: 10px;width: 100%" />
        </div>
      </a-radio-group>
    </a-modal>

    <!-- 认领金额弹出框 -->
    <a-modal
      :visible="formVisible"
      :confirmLoading="confirmLoading"
      :width="1200"
      :maskClosable="false"
      title="资金认领"
      @cancel="formVisible = false"
    >
      <a-row :gutter="24">
        <a-col :span="5">
          <p><strong>币种: {{ this.currentRecord.currency }}</strong></p>
        </a-col>
        <a-col :span="5">
          <p><strong>银行账款: {{ this.currentRecord.amount }}</strong></p>
        </a-col>
        <a-col :span="5">
          <p><strong>通联退款: {{ this.currentRecord.refund_amount }}</strong></p>
        </a-col>
        <a-col :span="5">
          <p><strong>未认领账款: {{ this.currentRecord.no_claim_amount }}</strong></p>
        </a-col>
        <a-col :span="4" v-if="this.currentRecord.pay_name && this.currentRecord.pay_name.startsWith('通联')">
          <p><a-button type="query" @click="autoClaim" :loading="autoLoading">自动认领</a-button></p>
        </a-col>
      </a-row>
      <a-table
        rowKey="id"
        :columns="claimColumns"
        :data-source="claimData"
        :pagination="false"
        :rowSelection="{ selectedRowKeys: this.selectedClaim, onChange: this.onSelectClaimChange }"
      >
        <span slot="serial" slot-scope="text, record, index">
          {{ index + 1 }}
        </span>
        <template slot="footer">
          <a-button type="primary" @click="handleClaimBack">
            资金反认领
          </a-button>
        </template>
      </a-table>
      <a-table
        v-if="currentRecord.cash_record_list && currentRecord.flag !== 1"
        rowKey="id"
        :columns="cashRecordColumns"
        :data-source="currentRecord.cash_record_list"
        :pagination="false"
      >
        <span slot="serial" slot-scope="text, record, index">
          {{ index + 1 }}
        </span>
        <div slot="orderNumList" slot-scope="text">
          <span v-for="item in text" :key="item" style="display:block">{{ item }}</span>
        </div>
        <div slot="InvoiceCashInfoList" slot-scope="text">
          <span style="display:block" v-for="item in text" :key="item.audit_id">
            金额：{{ item.amount }} 发票： {{ item.invoice_num_list.join('/') }}
          </span>
        </div>
      </a-table>
      <a-card title="查询订单" v-if="currentRecord.flag !== 1" style="margin-top:10px">
        <template slot="extra">
          <a-button type="query" @click="queryCapitalClaim">
            查询
          </a-button>
        </template>
        <a-form-model layout="vertical" :model="form" :rules="rules" ref="form">
          <a-row :gutter="20">
            <a-col :span="6">
              <a-form-model-item label="认领日期" prop="claim_date">
                <a-date-picker disabled v-model="form.claim_date" class="full-width" />
              </a-form-model-item>
            </a-col>
            <a-col :span="6">
              <a-form-model-item label="认领人" prop="claimant">
                <a-select disabled style="width: 100%" v-model="form.claimant">
                  <a-select-option v-for="op in userOps" :key="op.id">{{ op.name }}</a-select-option>
                </a-select>
              </a-form-model-item>
            </a-col>
            <a-col :span="6">
              <a-form-model-item label="部门" prop="department">
                <a-select
                  showSearch
                  :filter-option="filterOption"
                  allowClear
                  placeholder="请选择部门"
                  style="width: 100%"
                  @change="departmentChange"
                  v-model="form.department"
                >
                  <a-select-option v-for="op in this.GLOBAL.finDepartment" :key="op.value">{{
                    op.name
                  }}</a-select-option>
                </a-select>
              </a-form-model-item>
            </a-col>
            <a-col :span="6">
              <a-form-model-item label="发票号">
                <a-input allowClear v-model="form.invoice_no"></a-input>
              </a-form-model-item>
            </a-col>
            <a-col :span="12">
              <a-form-model-item label="客户" prop="shortName">
                <a-select
                  showSearch
                  allowClear
                  placeholder="请选择客户"
                  style="width: 100%"
                  :filter-option="false"
                  :not-found-content="fetching ? undefined : null"
                  v-model="form.customerId"
                  @search="getDepartmentCustomer"
                >
                  <template v-if="fetching" #notFoundContent>
                    <a-spin size="small" />
                  </template>
                  <a-select-option v-for="op in customerOps" :key="op.value">{{ op.name }}</a-select-option>
                </a-select>
              </a-form-model-item>
            </a-col>
            <a-col :span="12">
              <a-form-item label="开票日期">
                <a-range-picker @change="onInvoiceDateChange" v-model="defaultInvoiceDate" />
              </a-form-item>
            </a-col>
          </a-row>
        </a-form-model>
        <a-alert :message="showTotal" style="margin-bottom: 10px" type="info" show-icon />
        <a-table
          rowKey="id"
          :columns="orderColumns"
          :data-source="orderData"
          :pagination="false"
          :rowSelection="{ selectedRowKeys: this.selectedOrder, onChange: this.onSelectOrderChange }"
        >
          <span slot="serial" slot-scope="text, record, index">
            {{ index + 1 }}
          </span>
          <template slot="footer">
            <a-button type="primary" @click="handleSubmit">
              确认认领
            </a-button>
          </template>
        </a-table>
      </a-card>
      <template slot="footer">
        <a-button @click="formVisible = false">
          关闭
        </a-button>
      </template>
    </a-modal>

    <!-- 堆场认领金额弹出框 -->
    <a-modal
      :visible="ymsVisible"
      :confirmLoading="ymsConfirmLoading"
      :width="1000"
      :maskClosable="false"
      title="资金认领"
      @cancel="ymsCancel"
    >
      <a-row :gutter="20">
        <a-col :span="6">
          <p><strong>币种: {{ this.currentRecord.currency }}</strong></p>
        </a-col>
        <a-col :span="6">
          <p><strong>银行账款: {{ this.currentRecord.amount }}</strong></p>
        </a-col>
      </a-row>

      <!-- flag 资金认领状态(0未认领、1认领、2部分认领) -->
      <a-card title="查询订单" v-if="currentRecord.flag !== 1">
        <a-form-model
          layout="vertical"
          ref="ymsForm"
          :model="ymsForm"
          :rules="ymsRules"
        >
          <a-row :gutter="20">
            <a-col :span="8">
              <a-form-model-item label="认领日期">
                <a-range-picker disabled v-model="ymsForm.claim_date" class="full-width" />
              </a-form-model-item>
            </a-col>
            <a-col :span="8">
              <a-form-model-item label="认领人">
                <a-select disabled style="width: 100%" v-model="ymsForm.claimant">
                  <a-select-option v-for="op in userOps" :key="op.id">{{ op.name }}</a-select-option>
                </a-select>
              </a-form-model-item>
            </a-col>
            <a-col :span="8">
              <a-form-model-item label="堆场" prop="yard_id_list">
                <a-select
                  v-model="ymsForm.yard_id_list"
                  @change="yardChange"
                  mode="multiple"
                  allowClear
                >
                  <a-select-option v-for="item in yardList" :key="item.value">{{ item.name }}</a-select-option>
                </a-select>
              </a-form-model-item>
            </a-col>
          </a-row>
        </a-form-model>
        <a-statistic title="认领总金额（CNY）" :precision="2" :value="ymsClaimPriceTotal" />
      </a-card>

      <template slot="footer">
        <a-button type="primary" v-if="currentRecord.flag !== 1" @click="ymsHandleClaimSubmit">确认认领</a-button>
        <a-button type="primary" v-if="currentRecord.flag === 1" @click="ymsHandleClaimBack">资金反认领</a-button>
        <a-button @click="ymsCancel">关闭</a-button>
      </template>
    </a-modal>

    <!-- 仓储认领金额弹出框 -->
    <a-modal
      :visible="wmsVisible"
      :confirmLoading="wmsConfirmLoading"
      :width="1000"
      :maskClosable="false"
      title="资金认领"
      @cancel="wmsCancel"
    >
      <a-row :gutter="24">
        <a-col :span="8">
          <p><strong>币种: {{ this.currentRecord.currency }}</strong></p>
        </a-col>
        <a-col :span="8">
          <p><strong>银行账款: {{ this.currentRecord.amount }}</strong></p>
        </a-col>
        <a-col :span="8">
          <p><strong>账款日期:{{ moment(this.wmsForm.claim_date[0]).format('YYYY-MM-DD') }} ~ {{ moment(this.wmsForm.claim_date[1]).format('YYYY-MM-DD') }}</strong></p>
        </a-col>
      </a-row>

      <!-- flag 资金认领状态(0未认领、1认领、2部分认领) -->
      <a-card title="查询订单" v-if="currentRecord.flag !== 1">
        <a-form-model
          layout="vertical"
          ref="wmsForm"
          :model="wmsForm"
          :rules="wmsRules"
        >
          <a-row :gutter="24">
            <a-col :span="12">
              <a-form-model-item label="认领日期">
                <a-range-picker disabled v-model="wmsForm.claim_date" class="full-width" />
              </a-form-model-item>
            </a-col>
            <a-col :span="12">
              <a-form-model-item label="认领人">
                <a-select disabled style="width: 100%" v-model="wmsForm.claimant">
                  <a-select-option v-for="op in userOps" :key="op.id">{{ op.name }}</a-select-option>
                </a-select>
              </a-form-model-item>
            </a-col>
          </a-row>
        </a-form-model>
        <a-statistic title="认领总金额（CNY）" :precision="2" :value="wmsClaimPriceTotal" />
      </a-card>

      <template slot="footer">
        <a-button type="primary" v-if="currentRecord.flag !== 1" @click="wmsHandleClaimSubmit">确认认领</a-button>
        <a-button type="primary" v-if="currentRecord.flag === 1" @click="wmsHandleClaimBack">资金反认领</a-button>
        <a-button @click="wmsCancel">关闭</a-button>
      </template>
    </a-modal>

    <a-modal
      :visible="certDetailVisible"
      title="凭证信息"
      :width="1500"
      :maskClosable="false"
      @cancel="certDetailVisible = false"
    >
      <empty v-if="certData.length === 0" />
      <a-card title="收款凭证" v-if="certData.length > 0">
        <a-tabs v-model="currentCertId" type="card">
          <a-tab-pane v-for="item in certData" :key="item.id" :tab="item.cert_num + ' (' + transCertStatus(item.cert_status) + ')'">
            <a-table
              rowKey="id"
              :columns="certColumns"
              :data-source="item.cert_info_list"
              :pagination="false"
              :customRow="
                (record, index) => {
                  return {
                    style: {
                      background: item.cert_status === 1 ? '#ffe8e9' : item.cert_status === 2 ? '#f8fadf' : '#e7ffec'
                    }
                  }
                }
              "
            >
            </a-table>
          </a-tab-pane>
        </a-tabs>
      </a-card>
      <template slot="footer">
        <a-button type="danger" @click="delCertInfo">
          删除凭证
        </a-button>
        <a-button type="primary" @click="generateCert">
          重新生成凭证
        </a-button>
        <a-button type="primary" @click="handleConfirm">
          确认凭证
        </a-button>
        <a-button type="primary" @click="handleUnConfirm">
          反确认凭证
        </a-button>
        <a-button type="primary" @click="downloadCert">
          下载凭证
        </a-button>
        <!-- <a-button type="primary" @click="printCert">
          打印凭证
        </a-button> -->
        <a-button @click="certDetailVisible = false">
          关闭
        </a-button>
      </template>
    </a-modal>

    <a-modal
      :visible="printCertModelVisible"
      :confirmLoading="printCertModelLoading"
      :width="1520"
      :maskClosable="false"
      title="打印凭证"
      @cancel="printCertModelVisible = false"
    >
      <div id="printCertDataBill">
        <fms-cert-data-bill :certData="certPrintData" :name="certPrintName" />
      </div>
      <template slot="footer">
        <a-button key="submit" type="primary" id="printCertDataBillBtn" v-print="printCertDataBill">确认打印</a-button>
        <a-button type="primary" @click="printCertModelVisible = false">关闭</a-button>
      </template>
    </a-modal>

    <a-modal
      :visible="updatePayerModel"
      :confirmLoading="updatePayerModelLoading"
      :width="500"
      :maskClosable="false"
      title="指定付款人"
      @ok="confirmUpdatePayer"
      @cancel="updatePayerModel = false"
    >
      <a-form-item label="委托客户">
        <a-select
          showSearch
          label-in-value
          allowClear
          placeholder="请选择委托客户"
          style="width: 100%"
          :value="payerCustomerInfo"
          :filter-option="false"
          :not-found-content="payerFetching ? undefined : null"
          @search="getPayerCustomer"
          @change="changePayerCustomerInfo"
        >
          <template v-if="payerFetching" #notFoundContent>
            <a-spin size="small" />
          </template>
          <a-select-option v-for="op in payerCustomerOps" :key="op.value">{{ op.name }}</a-select-option>
        </a-select>
      </a-form-item>
    </a-modal>
  </a-card>
</template>
<script>
import { STable } from '@/components'
import moment from 'moment'
import { getSystemPage } from '@/api/system'
import { getFirstLetter } from '@/utils/util'
import {
  getClaimPage,
  claimFmsFinanceInfo,
  reverseClaimFmsFinanceInfo,
  getClaimOrderList,
  getFmsMetaOption,
  editIsAdvance,
  downloadCertById,
  previewCertById,
  delCert,
  certConfirm,
  certUnConfirm,
  generateChargeCert,
  getClaimInfo,
  getClaimRecord,
  editPayer,
  autoClaimMoney,
  getClaimTotalPriceByYardAndPayTime,
  confirmClaim,
  unconfirmClaim,
  generateYmsCert,
  generateWmsCert,
  getWmsClaimTotalPriceByPayTime,
  confirmWmsClaim,
  unconfirmWmsClaim,
  exportYmsPosPayDetailSheet,
  batchGenerateChargeCert,
  batchCertConfirm
} from '@/api/fms'
import { getCustomer, downloadExcel } from '@/api/common'
import debounce from 'lodash/debounce'
import { Empty } from 'ant-design-vue'
export default {
  components: {
    STable,
    Empty
  },
  data() {
    this.lastFetchId = 0
    this.getDepartmentCustomer = debounce(this.getDepartmentCustomer, 400)
    return {
      bankItem: '',
      showTotal: '合计：0',
      formVisible: false,
      confirmLoading: false,
      queryParam: {
        flag: 0,
        cert_status: undefined
      },
      defaultDate: [],
      claimDate: [],
      userOps: [],
      fmsUserOps: [],
      customerOps: [],
      claimAmounts: [],
      bankOps: [],
      columns: [
        {
          title: '#',
          scopedSlots: { customRender: 'serial' },
          align: 'center'
        },
        {
          title: '银行流水号',
          dataIndex: 'bank_serial_number'
        },
        {
          title: '部门',
          dataIndex: 'department',
          customRender: (text) => {
            if (text === 0) return '-'
            if (text === 100) return '混合部门'
            return this.GLOBAL.departmentMaps[text].name
          }
        },
        {
          title: '账款类型',
          dataIndex: 'is_advance',
          customRender: text => {
            switch (text) {
              case 0:
                return '未设置'
              case 1:
                return '非预收'
              case 2:
                return '预收'
            }
          }
        },
        {
          title: '状态',
          dataIndex: 'flag',
          customRender: text => {
            switch (text) {
              case 0:
                return '未认领'
              case 1:
                return '已认领'
              case 2:
                return '部分认领'
            }
          }
        },
        {
          title: '凭证号',
          dataIndex: 'cert_relation',
          scopedSlots: { customRender: 'certInfo' }
        },
        {
          title: '交易日',
          dataIndex: 'arrive_date',
          customRender: text => {
            return text && moment(text).format('YYYY/MM/DD')
          }
        },
        {
          title: '付款人名称',
          dataIndex: 'pay_name'
        },
        {
          title: '交易货币',
          dataIndex: 'currency'
        },
        {
          title: '交易金额',
          dataIndex: 'amount',
          customRender: text => {
            return (text.toFixed(2) + '').replace(/(\d{1,3})(?=(\d{3})+(?:$|\.))/g, '$1,')
          }
        },
        {
          title: '退款金额',
          dataIndex: 'refund_amount',
          customRender: (text) => {
            return (text.toFixed(2) + '').replace(/(\d{1,3})(?=(\d{3})+(?:$|\.))/g, '$1,')
          }
        },
        {
          title: `已认领金额`,
          dataIndex: `claim_amount`,
          customRender: text => {
            return (text.toFixed(2) + '').replace(/(\d{1,3})(?=(\d{3})+(?:$|\.))/g, '$1,')
          }
        },
        {
          title: '发票号',
          dataIndex: 'invoice_no_list',
          scopedSlots: { customRender: 'invoiceNoList' }
        },
        {
          title: '交易附言',
          dataIndex: 'remark'
        },
        {
          title: '收款人账号',
          dataIndex: 'receive_account'
        },
        {
          title: '收款人开户行',
          dataIndex: 'receive_bank'
        },
        {
          title: '操作',
          dataIndex: 'action',
          fixed: 'right',
          scopedSlots: { customRender: 'action' }
        }
      ],
      certColumns: [
        {
          title: '摘要',
          dataIndex: 'summary',
          width: 300
        },
        {
          title: '业务编号/发票号',
          dataIndex: 'number',
          width: 120
        },
        {
          title: '一级科目',
          dataIndex: 'first_subject',
          width: 200
        },
        {
          title: '明细科目',
          dataIndex: 'detail_subject',
          width: 200
        },
        {
          title: '外币金额',
          dataIndex: 'amount',
          width: 100
        },
        {
          title: '汇率',
          dataIndex: 'exchange_rate',
          width: 100
        },
        {
          title: '金额',
          children: [
            {
              title: '借方',
              dataIndex: 'borrow',
              customRender: text => {
                return text && (text.toFixed(2) + '').replace(/(\d{1,3})(?=(\d{3})+(?:$|\.))/g, '$1,')
              },
              align: 'right',
              width: 100
            },
            {
              title: '贷方',
              dataIndex: 'loan',
              customRender: text => {
                return text && (text.toFixed(2) + '').replace(/(\d{1,3})(?=(\d{3})+(?:$|\.))/g, '$1,')
              },
              align: 'right',
              width: 100
            }
          ]
        }
      ],
      currentCertRecord: {},
      currentCertId: undefined,
      currentCert: {},
      certDetailVisible: false,
      certData: [],
      form: {
        claim_date: moment(),
        claimant: this.$store.getters.userInfo.id,
        department: this.$store.getters.userInfo.department,
        invoice_no: '',
        customerId: undefined,
        invoice_start_date: null,
        invoice_end_date: null
      },
      defaultInvoiceDate: [],
      rules: {
        claim_date: [{ required: true, message: '请填写认领日期', trigger: 'change' }],
        claimant: [{ required: true, message: '请选择认领人', trigger: 'blur' }],
        department: [{ required: true, message: '请选择认领人所在部门', trigger: 'blur' }]
      },
      selectedRowKeys: [],
      businessCheckList: [],
      moneyFlagOps: [
        {
          value: 0,
          name: '未认领'
        },
        {
          value: 1,
          name: '已认领'
        },
        {
          value: 2,
          name: `部分认领`
        }
      ],
      isAdvanceOps: [
        {
          value: 1,
          name: '非预收'
        },
        {
          value: 2,
          name: '预收'
        },
        {
          value: 0,
          name: '未设置'
        }
      ],
      loadData: parameter => {
        return getClaimPage(Object.assign(parameter, this.queryParam)).then(res => {
          return {
            list: res.records,
            total: res.total
          }
        })
      },
      fetching: false,
      payName: null,
      accountTypeVisible: false,
      accountType: 1,
      accountDepartment: undefined,
      dealDate: undefined,
      endDealDate: undefined,
      printCertModelVisible: false,
      printCertModelLoading: false,
      printCertDataBill: {
        id: `printCertDataBill`,
        popTitle: ``,
        extraCss: ``,
        extraHead: `<meta http-equiv="Content-Language" content="zh-cn" />`
      },
      certPrintData: [],
      certPrintName: '',
      claimColumns: [
        {
          title: '#',
          scopedSlots: { customRender: 'serial' },
          width: 50,
          align: 'center'
        },
        {
          title: '流水号',
          dataIndex: 'record_no',
          width: 200
        },
        {
          title: '部门',
          dataIndex: 'claim_ant_department',
          customRender: text => {
            return this.GLOBAL.departmentMaps[text].name
          }
        },
        {
          title: '订单状态',
          dataIndex: 'fee_status',
          customRender: text => {
            return this.GLOBAL.feeStatusMaps[text].name
          }
        },
        {
          title: '发票号',
          dataIndex: 'invoice_no',
          customRender: text => {
            return text || ''
          }
        },
        {
          title: '开票时间',
          dataIndex: 'invoice_date',
          customRender: text => {
            return text && moment(text).format('YYYY/MM/DD')
          }
        },
        {
          title: '认领时间',
          dataIndex: 'claim_date',
          customRender: text => {
            return text && moment(text).format('YYYY/MM/DD')
          }
        },
        {
          title: '认领人',
          dataIndex: 'claim_ant_name'
        },
        {
          title: '认领金额',
          dataIndex: 'claim_amount'
        }
      ],
      claimData: [],
      currentRecord: {},
      orderColumns: [
        {
          title: '#',
          scopedSlots: { customRender: 'serial' },
          width: 50,
          align: 'center'
        },
        {
          title: '流水号',
          dataIndex: 'record_no'
        },
        {
          title: '部门',
          dataIndex: 'department',
          customRender: text => {
            return this.GLOBAL.departmentMaps[text].name
          }
        },
        {
          title: '客户名称',
          dataIndex: 'customer_name'
        },
        {
          title: '币种',
          dataIndex: 'currency_name'
        },
        {
          title: '发票号',
          dataIndex: 'invoice_no'
        },
        {
          title: '开票时间',
          dataIndex: 'created_at',
          customRender: text => {
            return text && moment(text).format('YYYY/MM/DD')
          }
        },
        {
          title: '总金额',
          dataIndex: 'sum_amount'
        },
        {
          title: '可认领金额',
          dataIndex: 'can_claim_amount'
        }
      ],
      orderData: [],
      selectedOrder: [],
      selectedClaim: [],
      certStatusOps: [
        {
          value: 1,
          name: '待确认'
        },
        {
          value: 2,
          name: '已确认'
        },
        {
          value: 3,
          name: '已下载'
        }
      ],
      updatePayerModel: false,
      updatePayerModelLoading: false,
      payerCustomerOps: [],
      payerCustomerId: null,
      payerCustomerInfo: undefined,
      payerFetching: false,
      updatePayerId: null,
      autoLoading: false,
      cashRecordColumns: [
        {
          title: '#',
          scopedSlots: { customRender: 'serial' },
          width: 50,
          align: 'center'
        },
        {
          title: '交易号',
          dataIndex: 'trx_id'
        },
        {
          title: '订单编号',
          dataIndex: 'order_num_list',
          scopedSlots: { customRender: 'orderNumList' }
        },
        {
          title: '交易时间',
          dataIndex: 'pay_date',
          customRender: text => {
            return text && text.substring(0, 10)
          }
        },
        {
          title: '交易金额',
          dataIndex: 'amount',
          customRender: text => {
            return text && this.moneyFormatter(text)
          }
        },
        {
          title: '发票信息',
          dataIndex: 'invoice_cash_info_list',
          scopedSlots: { customRender: 'InvoiceCashInfoList' }
        }
      ],
      ymsClaimPriceTotal: 0,
      wmsClaimPriceTotal: 0,
      ymsVisible: false,
      wmsVisible: false,
      ymsConfirmLoading: false,
      wmsConfirmLoading: false,
      ymsForm: {
        claim_date: [],
        claimant: this.$store.getters.userInfo.id,
        yard_id_list: []
      },
      wmsForm: {
        claim_date: [],
        claimant: this.$store.getters.userInfo.id
      },
      ymsRules: {
        yard_id_list: [{ required: true, message: '请选择堆场', trigger: 'blur' }]
      },
      wmsRules: {
      },
      yardList: [
        { value: 1, name: '奥吉浦东' },
        { value: 2, name: '奥吉江东' },
        { value: 4, name: '奥吉钢四堆场' },
        { value: 5, name: '奥吉华东堆场' }
      ],
      popVisible: false
    }
  },
  created() {
    getSystemPage('user', {
      page: 1,
      page_size: 1000
    }).then(v => {
      this.userOps = v.list
      this.fmsUserOps = []
      this.userOps.forEach(item => {
        if (item.department === 5) {
          this.fmsUserOps.push(item)
        }
      })
    })
    getFmsMetaOption('fms_bank').then(v => {
      this.bankOps = v
    })
  },
  watch: {
    selectedOrder() {
      let total = 0
      for (const item of this.orderData) {
        if (this.selectedOrder.includes(item.id)) {
          total += item.can_claim_amount
        }
      }
      this.showTotal = `合计：${total}`
    }
  },
  methods: {
    moment,
    handleExportPosPayDetailSheet() {
      const startDate = this.queryParam['pay_start_date']
      const endDate = this.queryParam['pay_end_date']
      if (startDate || endDate) {
        exportYmsPosPayDetailSheet(this.queryParam).then(res => {
          if (res !== null) {
            const fileInfo = { fileName: res }
            downloadExcel(fileInfo).then(downRes => {
              const data = downRes.data
              let fileName = ''
              const contentDisposition = downRes.headers['content-disposition']
              if (contentDisposition) {
                fileName = decodeURI(escape(contentDisposition.split('=')[1]))
              }
              const url = window.URL.createObjectURL(
                new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' })
              )
              const link = document.createElement('a')
              link.style.display = 'none'
              link.href = url
              link.setAttribute('download', fileName)
              document.body.appendChild(link)
              link.click()
              document.body.removeChild(link)
            })
          }
        }).finally(_ => {
          this.popVisible = false
        })
      } else {
        this.$notification['warn']({
          message: '提示',
          description: '请选择导出月份'
        })
      }
    },
    onExportYmsPosChargeDateChange(date, dateStr) {
      if (dateStr[0]) {
        this.queryParam['pay_start_date'] = date[0].startOf('day').format('YYYY-MM-DD HH:mm:ss')
        this.queryParam['pay_end_date'] = date[1].endOf('day').format('YYYY-MM-DD HH:mm:ss')
      } else {
        this.queryParam['pay_start_date'] = null
        this.queryParam['pay_end_date'] = null
      }
    },
    wmsCancel() {
      this.wmsVisible = false
      this.wmsConfirmLoading = false
      this.wmsClaimPriceTotal = 0
      this.wmsForm = {
        claim_date: [],
        claimant: this.$store.getters.userInfo.id
      }
    },
    wmsChange() {
      const reqParam = {
        'start_date': this.wmsForm.claim_date[0],
        'end_date': this.wmsForm.claim_date[1]
      }
      getWmsClaimTotalPriceByPayTime(reqParam).then(res => {
        this.wmsClaimPriceTotal = res
      })
    },
    wmsHandleClaimSubmit() {
      const reqParam = {
        'start_date': this.wmsForm.claim_date[0],
        'end_date': this.wmsForm.claim_date[1],
        'claim_id': this.currentRecord.id,
        'claim_price': this.currentRecord.amount
      }
      this.wmsConfirmLoading = true
      confirmWmsClaim(reqParam).then(res => {
        this.$message.success('资金认领成功!')
        this.wmsVisible = false
        this.$refs.table.refresh(true)
      }).finally(_ => {
        this.wmsConfirmLoading = false
        this.selectedOrder = []
      })
    },
    wmsHandleClaimBack() {
      const reqParam = {
        'start_date': this.wmsForm.claim_date[0],
        'end_date': this.wmsForm.claim_date[1],
        'claim_id': this.currentRecord.id,
        'claim_price': this.currentRecord.amount
      }
      this.wmsConfirmLoading = true
      unconfirmWmsClaim(reqParam).then(res => {
        this.$message.success('资金反认领成功!')
        this.wmsVisible = false
        this.$refs.table.refresh(true)
      }).finally(_ => {
        this.wmsConfirmLoading = false
      })
    },
    // 堆场认领资金弹出框关闭事件
    ymsCancel() {
      this.ymsVisible = false
      this.ymsConfirmLoading = false
      this.ymsClaimPriceTotal = 0
      this.ymsForm = {
        claim_date: [],
        claimant: this.$store.getters.userInfo.id,
        yard_id_list: []
      }
    },
    // 根据所选堆场查询通联指定日期期间的费用总和
    yardChange() {
      if (this.ymsForm.yard_id_list.length > 0) {
        const reqParam = {
          'yard_id_list': this.ymsForm.yard_id_list,
          'start_date': this.ymsForm.claim_date[0],
          'end_date': this.ymsForm.claim_date[1]
        }
        getClaimTotalPriceByYardAndPayTime(reqParam).then(res => {
          this.ymsClaimPriceTotal = res
        })
      }
    },
    // 堆场资金认领操作
    ymsHandleClaimSubmit() {
      if (this.ymsClaimPriceTotal === 0) {
        this.$message.warning('认领金额为0')
        return
      }
      const reqParam = {
        'yard_id_list': this.ymsForm.yard_id_list,
        'start_date': this.ymsForm.claim_date[0],
        'end_date': this.ymsForm.claim_date[1],
        'claim_id': this.currentRecord.id,
        'claim_price': this.currentRecord.amount
      }
      this.confirmLoading = true
      confirmClaim(reqParam).then(res => {
        this.$message.success('资金认领成功!')
        this.ymsVisible = false
        this.$refs.table.refresh(true)
      }).finally(_ => {
        this.confirmLoading = false
        this.selectedOrder = []
        this.selectedRowKeys = []
      })
    },
    // 堆场资金反认领操作
    ymsHandleClaimBack() {
      if (this.ymsClaimPriceTotal === 0) {
        this.$message.warning('反认领金额为0')
        return
      }
      const reqParam = {
        'yard_id_list': this.ymsForm.yard_id_list,
        'start_date': this.ymsForm.claim_date[0],
        'end_date': this.ymsForm.claim_date[1],
        'claim_id': this.currentRecord.id,
        'claim_price': this.currentRecord.amount
      }
      this.confirmLoading = true
      unconfirmClaim(reqParam).then(res => {
        this.$message.success('资金反认领成功!')
        this.ymsVisible = false
        this.$refs.table.refresh(true)
      }).finally(_ => {
        this.confirmLoading = false
      })
    },
    // 编辑付款人
    updatePayer(value) {
      if (value) {
        this.getPayerCustomer(value.pay_name)
        this.updatePayerId = value.id
        this.updatePayerModel = true
      }
    },
    // 提交指定付款人
    confirmUpdatePayer() {
      editPayer({
        id: this.updatePayerId,
        customer_id: this.payerCustomerId
      })
        .then(_ => {
          this.$notification['success']({
            message: '提示',
            description: '指定成功'
          })
          this.updatePayerModel = false
        })
        .finally(_ => {
          this.updatePayerModelLoading = false
        })
    },
    getPayerCustomer(value) {
      this.lastFetchId += 1
      const fetchId = this.lastFetchId
      this.payerCustomerOps = []
      this.payerFetching = true
      getCustomer({
        department: null,
        customerName: value
      }).then(res => {
        if (fetchId !== this.lastFetchId) {
          return
        }
        if (res && res.length > 0) {
          this.payerCustomerInfo = {
            'key': res[0].value, 'label': res[0].name
          }
          this.payerCustomerId = res[0].value
        } else {
          this.payerCustomerInfo = undefined
          this.payerCustomerId = null
        }
        this.payerCustomerOps = res
        this.payerFetching = false
      })
    },
    changePayerCustomerInfo(value) {
      if (value === undefined) {
        // 委托客户选择框中无值
        this.payerCustomerInfo = undefined;
        this.payerCustomerId = null;
      } else {
        // 委托客户选择框中有值
        this.payerCustomerInfo = value;
        this.payerCustomerId = value.key;
      }
      this.payerCustomerOps = []
      this.payerFetching = false
    },
    onSelectChange(selectedRowKeys, selectedRows) {
      this.selectedRowKeys = selectedRowKeys
      this.selectedRows = selectedRows
    },
    onSelectOrderChange(selectedRowKeys, selectedRows) {
      this.selectedOrder = selectedRowKeys
    },
    onSelectClaimChange(selectedRowKeys, selectedRows) {
      this.selectedClaim = selectedRowKeys
    },
    departmentChange(value) {
      this.getDepartmentCustomer(this.payName)
    },
    getDepartmentCustomer(value) {
      this.lastFetchId += 1
      const fetchId = this.lastFetchId
      this.customerOps = []
      this.fetching = true
      getCustomer({
        department: this.form.department,
        customerName: value
      }).then(res => {
        if (fetchId !== this.lastFetchId) {
          return
        }
        if (res && res.length > 0) {
          this.form.customerId = res[0].value
        }
        this.customerOps = res
        this.fetching = false
      })
    },
    // 资金认领
    handleClaim(record) {
      if (record.department === 6 && record.pay_name.includes('通联')) {
        // 显示堆场自定义资金认领弹出框
        this.ymsVisible = true
        this.getYmsClaimData(record.id)
      } else if (record.department === 1 && record.pay_name.includes('通联')) {
        // 显示仓储自定义资金认领弹出框
        this.wmsVisible = true
        this.getWmsClaimData(record.id)
      } else {
        // 其余部门显示统一资金认领弹出框
        this.resetFields()
        this.orderData = []
        this.payName = record.pay_name
        this.getDepartmentCustomer(this.payName)
        this.getClaimData(record.id)
      }
    },
    getClaimData(id) {
      getClaimRecord({ 'id': id }).then(res => {
          this.currentRecord = res
          getClaimInfo({ 'id': id }).then(res => {
            this.claimData = res
            this.form.id = this.currentRecord.id
            this.form.claimant = this.$store.getters.userInfo.id
            this.formVisible = true
          })
        })
    },
    getYmsClaimData(id) {
      getClaimRecord({ 'id': id }).then(res => {
        this.currentRecord = res
        this.ymsForm.claim_date = [
          moment(res.deal_date).format('YYYY-MM-DD HH:mm:ss'),
          moment(res.end_deal_date).format('YYYY-MM-DD HH:mm:ss')
        ]
      })
    },
    getWmsClaimData(id) {
      getClaimRecord({ 'id': id }).then(res => {
        this.currentRecord = res
        this.wmsForm.claim_date = [
          moment(res.deal_date).format('YYYY-MM-DD HH:mm:ss'),
          moment(res.end_deal_date).format('YYYY-MM-DD HH:mm:ss')
        ]
        this.wmsChange()
      })
    },
    onInvoiceDateChange(date) {
      if (date[0]) {
        this.form.invoice_start_date = date[0].startOf('day').format('YYYY-MM-DD HH:mm:ss')
        this.form.invoice_end_date = date[1].endOf('day').format('YYYY-MM-DD HH:mm:ss')
      } else {
        this.form.invoice_start_date = null
        this.form.invoice_end_date = null
      }
    },
    // 查询未认领的资金
    queryCapitalClaim() {
      this.claimAmounts = []
      const data = {
        // 部门id
        department: this.form.department,
        // 客户id
        customerId: this.form.customerId,
        // 发票号
        invoiceNo: this.form.invoice_no,
        // 币种
        currency: this.currentRecord.currency,
        // 开票时间
        invoiceStartDate: this.form.invoice_start_date,
        // 开票结束时间
        invoiceEndDate: this.form.invoice_end_date
      }
      if (this.form.department || this.form.invoice_no) {
        getClaimOrderList(data).then(res => {
          this.orderData = res
        })
      }
    },
    // 资金反认领
    handleClaimBack() {
      reverseClaimFmsFinanceInfo({
        id: this.currentRecord.id,
        record_id_list: this.selectedClaim
      }).then(_ => {
        this.$notification['success']({
          message: '提示',
          description: '反认领成功'
        })
        this.getClaimData(this.currentRecord.id)
        this.queryCapitalClaim()
        this.$refs.table.refresh(false)
      })
    },
    onDateChange(date, dateString) {
      if (date[0]) {
        const _date = [
          date[0].startOf('day').format('YYYY-MM-DD HH:mm:ss'),
          date[1].endOf('day').format('YYYY-MM-DD HH:mm:ss')
        ]
        this.queryParam['arrive_date'] = _date
      } else {
        this.queryParam['arrive_date'] = []
      }
    },
    onClaimDateChange(date, dateString) {
      if (date[0]) {
        const _date = [
          date[0].startOf('day').format('YYYY-MM-DD HH:mm:ss'),
          date[1].endOf('day').format('YYYY-MM-DD HH:mm:ss')
        ]
        this.queryParam['claim_date'] = _date
      } else {
        this.queryParam['claim_date'] = []
      }
    },
    filterOption(input, option) {
      return (
        option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0 ||
        getFirstLetter(option.componentOptions.children[0].text)
          .toLowerCase()
          .indexOf(input.toLowerCase()) >= 0
      )
    },
    // 重置表单数据
    resetFields() {
      this.form = {
        claim_date: moment(),
        claimant: this.$store.getters.userInfo.id,
        department: this.$store.getters.userInfo.department,
        invoice_no: '',
        customerId: undefined
      }
    },
    moneyFlagChange() {
      this.$refs.table.refresh(false)
    },
    isAdvanceFlagChange() {
      this.$refs.table.refresh(false)
    },
    // 认领金额操作提交事件
    handleSubmit() {
      this.confirmLoading = true
      this.$refs.form.validate(valid => {
        if (valid) {
          const data = {
            ...this.form,
            id: this.currentRecord.id,
            record_id_list: this.selectedOrder,
            claim_date: moment(this.form.claim_date).valueOf()
          }
          claimFmsFinanceInfo(data)
            .then(_ => {
              this.$notification['success']({
                message: '提示',
                description: '认领成功'
              })
              this.$refs.table.refresh(false)
              this.getClaimData(this.currentRecord.id)
              this.queryCapitalClaim()
              this.handleCloseAccountType()
            })
            .finally(() => {
              this.confirmLoading = false
              this.selectedOrder = []
              this.selectedRowKeys = []
              this.selectedRows = []
            })
        } else {
          return false
        }
      })
    },
    autoClaim(record) {
      this.autoLoading = true
      autoClaimMoney({
        id: this.currentRecord.id
      }).then(res => {
        if (res) {
          this.$notification['success']({
            message: '提示',
            description: '自动认领成功'
          })
          this.$refs.table.refresh(false)
          this.getClaimData(this.currentRecord.id)
          this.queryCapitalClaim()
        }
      }).finally(_ => {
        this.autoLoading = false
      })
    },
    handleAccountType() {
      this.accountTypeVisible = true
      this.accountType = 1
    },
    onDealDateChange(date, dateString) {
      if (date[0]) {
        this.dealDate = date[0].startOf('day').format('YYYY-MM-DD HH:mm:ss')
        this.endDealDate = date[1].endOf('day').format('YYYY-MM-DD HH:mm:ss')
      } else {
        this.dealDate = null
        this.endDealDate = null
      }
    },
    // 账款类型
    handleSubmitAccountType() {
      this.confirmLoading = true
      editIsAdvance({
        ids: this.selectedRowKeys,
        account_type: this.accountType,
        account_department: this.accountDepartment,
        start_deal_date: this.dealDate,
        end_deal_date: this.endDealDate
      })
        .then(_ => {
          this.$notification['success']({
            message: '提示',
            description: `资金已设置为${this.accountType === 1 ? '非预收' : '预收'}`
          })
          this.$refs.table.refresh(false)
          this.handleCloseAccountType()
        })
        .finally(_ => {
          this.confirmLoading = false
        })
    },
    handleCloseAccountType() {
      this.accountTypeVisible = false
      this.selectedRowKeys = []
      this.selectedRows = []
    },
    previewCert(record) {
      this.currentCertRecord = record
      this.previewChargeCert(record)
      this.certDetailVisible = true
    },
    previewChargeCert(record) {
      let type
      if (record.pay_name.includes('通联') && record.department === 6) {
        type = 5 // 查看堆场部门通联交易生成凭证
      } else if (record.pay_name.includes('通联') && record.department === 1) {
        type = 6 // 查看仓储部门通联交易生成凭证
      } else {
        type = 2 // 查看其他部门生成凭证
      }
      previewCertById({
        id: record.id,
        type: type,
        batchNum: record.batch_num
      }).then(res => {
        this.certData = res || []
        if (res && res.length > 0) {
          let cert = null
          for (const item of res) {
            if (item.id === this.currentCertId) {
              cert = item
            }
          }
          if (cert) {
            this.currentCertId = cert.id
            this.currentCert = cert
          } else {
            this.currentCertId = res[0].id
            this.currentCert = res[0]
          }
        }
      })
    },
    generateCert() {
      if (this.currentCertRecord.department === 6 && this.currentCertRecord.pay_name.includes('通联')) {
        // 生成堆场专属凭证
        generateYmsCert({ 'id': this.currentCertRecord.id }).then(res => {
          this.$message.success('已生成凭证')
          this.$refs.table.refresh(false)
          this.previewChargeCert(this.currentCertRecord)
        })
      } else if (this.currentCertRecord.department === 1 && this.currentCertRecord.pay_name.includes('通联')) {
        // 生成仓储专属凭证
        generateWmsCert({ 'id': this.currentCertRecord.id }).then(res => {
          this.$message.success('已生成凭证')
          this.$refs.table.refresh(false)
          this.previewChargeCert(this.currentCertRecord)
        })
      } else {
        // 其他生成凭证规则不变
        generateChargeCert({
          id: this.currentCertRecord.id
        }).then(res => {
          if (res) {
            this.$message.success('已生成凭证')
            this.$refs.table.refresh(false)
            this.previewChargeCert(this.currentCertRecord)
          }
        })
      }
    },
    batchGenerateCert() {
      this.$confirm({
        title: '确认生成凭证?',
        content: '是否要为所选的未生成凭证的单据生成凭证?',
        okText: '确认',
        cancelText: '取消',
        onOk: () => {
          batchGenerateChargeCert(this.selectedRowKeys).then(res => {
            this.$notification['success']({
                message: '提示',
                description: res
              })
              this.$refs.table.refresh(false)
          })
        }
      })
    },
    batchHandleConfirm() {
      if (this.selectedRowKeys.length > 0) {
        batchCertConfirm(this.selectedRowKeys).then(res => {
          if (res) {
            this.$message.success('凭证确认成功')
            this.$refs.table.refresh(false)
          }
        })
      }
    },
    downloadCert(flag) {
      let list = []
      let isAllinpay = false;
      let department = 0
      if (flag === 'all') {
        list = this.selectedRowKeys
        console.info(this.selectedRows);
        isAllinpay = this.selectedRows[0].pay_name.includes('通联')
        department = this.selectedRows[0].department
      } else {
        list.push(this.currentCertRecord.id)
        isAllinpay = this.currentCertRecord.pay_name.includes('通联')
        department = this.currentCertRecord.department
      }
      let type
      if (isAllinpay && department === 6) {
        type = 5 // 查看堆场部门通联交易生成凭证
      } else if (isAllinpay && department === 1) {
        type = 6 // 查看仓储部门通联交易生成凭证
      } else {
        type = 2 // 查看其他部门生成凭证
      }
      downloadCertById({
        list,
        type: type
      }).then(res => {
        if (res !== null) {
          const fileInfo = {
            fileName: res
          }
          downloadExcel(fileInfo).then(downRes => {
            const data = downRes.data
            let fileName = ''
            const contentDisposition = downRes.headers['content-disposition']
            if (contentDisposition) {
              fileName = decodeURI(escape(contentDisposition.split('=')[1]))
            }
            const url = window.URL.createObjectURL(
              new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' })
            )
            const link = document.createElement('a')
            link.style.display = 'none'
            link.href = url
            link.setAttribute('download', fileName)
            document.body.appendChild(link)
            link.click()
            document.body.removeChild(link)
            this.$refs.table.refresh(false)
            if (flag !== 'all') {
              this.previewChargeCert(this.currentCertRecord)
            }
          })
        }
      })
    },
    printCert() {
      this.certPrintData = this.currentCert.cert_info_list
      this.certPrintName = '收款凭证'
      this.printCertModelVisible = true
    },
    moneyFormatter(money) {
      return (money.toFixed(2) + '').replace(/(\d{1,3})(?=(\d{3})+(?:$|\.))/g, '$1,')
    },
    delCertInfo() {
      delCert({
        id: this.currentCertId
      }).then(res => {
        if (res) {
          this.$message.success('删除凭证成功')
          this.$refs.table.refresh(false)
          this.previewChargeCert(this.currentCertRecord)
        }
      })
    },
    handleConfirm() {
      certConfirm({
        id: this.currentCertId
      }).then(res => {
        if (res) {
          this.$message.success('凭证确认成功')
          this.$refs.table.refresh(false)
          this.previewChargeCert(this.currentCertRecord)
        }
      })
    },
    handleUnConfirm() {
      certUnConfirm({
        id: this.currentCertId
      }).then(res => {
        if (res) {
          this.$message.success('凭证反确认成功')
          this.$refs.table.refresh(false)
          this.previewChargeCert(this.currentCertRecord)
        }
      })
    },
    transCertStatus(status) {
      switch (status) {
        case 1:
          return '待确认'
        case 2:
          return '已确认'
        case 3:
          return '已下载'
        default:
          return ''
      }
    }
  }
}
</script>
